import { css } from '@emotion/react';
import { Button, Dialog, MobileStepper } from '@mui/material';
import React, { memo, useState } from 'react';
import { MdCheck } from 'react-icons/md';
import { BsGearWideConnected, BsLightning, BsFillCaretRightFill, BsPeople } from 'react-icons/bs';
import { AppLink } from '~/components/AppLink';
import useMedia from '~/hooks/useMedia';
import { FuturesaiLandingLayout } from '~/modules/futuresai-static/FuturesaiLandingLayout';
import { ai, flex, oneGridArea } from '~/modules/AppLayout/FlexGridCss';
import { globalBlueGreen, globalBlueGrey, globalGrey, globalOrange, } from '~/modules/AppLayout/Colors';
import Head from 'next/head';
import { KeyboardArrowRight, KeyboardArrowLeft } from '@mui/icons-material';
import { fontWeight600, fontWeight100 } from '~/css/font';
const teamCarouselGroupCss = css `
  ${flex.wrap.allCenter};
  padding: 0 40px;
`;
const manPositionBoxCss = css `
  ${flex.h.allCenter}
  margin: -6px 0 0;
  background: ${globalBlueGrey.bg500};
  border-radius: 8px;
  color: white;
  ${fontWeight600};
  font-size: 1.5rem;
  min-height: 64px;
`;
const newServices = [
    {
        title: '微股力',
        description: ['提供靜態台指、海期', '盤前規劃／盤後報告'],
        url: '/futuresai/product-intro/scantrader',
    },
    {
        title: '看盤系統',
        description: ['多達50+種靜態盤中指標', '帶您即時掌握大戶走勢'],
        url: '/futuresai/product-intro/main',
    },
    {
        title: '影音專區',
        description: ['每周更新影片', '獲取最新市場動向'],
        url: 'https://www.youtube.com/channel/UCxn_ZSz7lavsYxzlJKNr-LA/videos',
    },
    {
        title: '客製化',
        description: ['提供各大券商、投顧', '客製化投資平台服務'],
    },
];
const paragraphyTitleCss = css `
  color: ${globalOrange.o900};
  margin: 16px 0;
  font-size: 2rem;
  ${fontWeight600};
`;
const paragraphyContentCss = css `
  margin: 0;
  color: ${globalGrey.g900};
  font-size: 1.2rem;
`;
const sectionTitle = css `
  ${flex.h.crossCenter};
  color: ${globalBlueGrey.bg700};
  font-size: 2.5rem;
  ${fontWeight600};
  margin-left: 8vw;
  p:nth-of-type(2) {
    color: ${globalBlueGreen.bg700};
  }
  p:nth-of-type(3) {
    ${fontWeight100};
    color: ${globalGrey.g500};
    margin: 0 32px;
  }
  p:nth-of-type(4) {
    font-size: 2rem;
    color: ${globalBlueGrey.bg400};
  }
`;
const ACTIVITY_BANNERS = [
    { alt: 'Join us', src: '/futuresai/index/futuresai-banner-1.png', href: '' },
    {
        alt: 'Activity Banner',
        src: '/futuresai/index/futuresai-banner-4.png',
        href: 'https://www.facebook.com/1960370187549322/posts/2926257354293929/',
    },
];
const BANNER_MAX_STEP = 2;
const bannerStepperCss = css `
  background: transparent;
  color: transparent;
  width: 100%;
  height: 40%;
  margin: auto;
  & > button {
    background: ${globalGrey.g50}77;
    height: 100%;
  }
`;
const FuturesHomeNextPage = () => {
    const { isPhone, isPc, isPad } = useMedia();
    const bannerImageCss = css `
    width: 100%;
  `;
    const [activeStep, setActiveStep] = useState(0);
    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };
    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };
    return (<FuturesaiLandingLayout>
      <Head>
        <title>期天大勝</title>
        <meta property='og:title' content='期天大勝'/>
        <meta property='og:description' content='對於金融交易，本團隊以科學化的方式切入，建構大數據資料庫，用程式演算法量化各種交易現象。 新世代交易數據系統，不能只有傳統K棒指標。'/>
        <meta property='og:image' content='/futuresai/icon.png'/>
      </Head>
      <div css={css `
          ${oneGridArea.parentGrid('one')}
          position: relative;
          & > * {
            ${oneGridArea.childGridItem('one')}
          }
        `}>
        <AppLink href={ACTIVITY_BANNERS[activeStep].href} target='_blank'>
          <img css={bannerImageCss} alt={ACTIVITY_BANNERS[activeStep].alt} src={ACTIVITY_BANNERS[activeStep].src}/>
        </AppLink>
        <MobileStepper css={bannerStepperCss} variant='text' steps={BANNER_MAX_STEP} position='static' activeStep={activeStep} nextButton={<Button size='large' onClick={handleNext} disabled={activeStep === BANNER_MAX_STEP - 1}>
              {<KeyboardArrowRight />}
            </Button>} backButton={<Button size='large' onClick={handleBack} disabled={activeStep === 0}>
              {<KeyboardArrowLeft />}
            </Button>}/>
      </div>

      <div css={css `
          ${isPc && 'height: 60vh;'}
          background: url('/futuresai/index/serviceBack.png');
          background-size: 80%;
          background-repeat: no-repeat;
          background-position: top;
        `}>
        <div css={sectionTitle}>
          <p>提供</p>
          <p>服務</p>
        </div>
        <div css={css `
            padding: ${isPhone ? '32px 8px' : '64px 0'};
          `}>
          <Services />
        </div>
      </div>
      <div css={css `
          background: ${isPc ? `url('/futuresai/index/aboutTeamBack.png')` : null};
          background-repeat: no-repeat;
          background-position: top;
          background-size: 100vw;
          position: relative;
        `}>
        <div css={css `
            ${sectionTitle};
            position: absolute;
            top: -72px;
          `}>
          <p>關於</p>
          <p>我們</p>
          {isPhone || (<>
              <p>|</p>
              <p>用跨界思維，打造投資未來</p>
            </>)}
        </div>
        <div css={css `
            padding: ${isPhone ? '32px 8px' : '64px 0'};
          `}>
          <AboutUs />
        </div>
        <div css={css `
            ${sectionTitle};
            margin-top: -24px;
          `}>
          <p>團隊</p>
          <p>介紹</p>
          {isPhone || <p>|</p>}
        </div>
        <div css={css `
            padding-bottom: 32px;
            ${isPad && 'padding:0'};
          `}>
          <Team />
        </div>
      </div>
    </FuturesaiLandingLayout>);
};
const Services = memo(function Services(props) {
    const { isPc } = useMedia();
    return (<div css={css `
        ${flex.h.allCenter}
        ${!isPc && 'flex-direction:column'};
      `}>
      {newServices.map((service, index) => (<ServiceDescribeCard index={index} key={service.title} text={service.title} url={service.url} description={service.description}/>))}
    </div>);
});
const ServiceDescribeCard = memo(function ServiceDescribeCard(props) {
    const { isPc, isPhone } = useMedia();
    return (<AppLink href={props.url ?? '#'} rootCSS={css `
        ${flex.v.default};
        min-width: 320px;
        margin-${isPc ? 'right' : 'bottom'}: 24px;
        &:hover {
          transform: scale(1.1);
          p{
            color:${globalGrey.g900}
          }
        }
      `}>
      <div css={css `
          ${flex.h.allCenter}
          background: ${globalBlueGrey.bg100};
          color: ${globalBlueGrey.bg700};
          font-size: 1.5rem;
          ${fontWeight600};
          border-radius: 8px 8px 0 0;
          p {
            margin: 16px;
          }
        `}>
        <MdCheck />
        <p> {props.text}</p>
      </div>
      <div css={css `
          ${flex.v.allCenter}
          background: ${globalGrey.g100};
          padding: ${isPhone ? 32 : 64}px 16px;
          color: ${globalBlueGrey.bg700};
          font-size: 1.2rem;
          ${fontWeight600};
          border-radius: 0 0 8px 8px;
          p {
            margin: 0 32px;
          }
        `}>
        {props.description.map((des, index) => (<p key={index}>{des}</p>))}
      </div>
    </AppLink>);
});
const AboutUs = memo(function AboutUs() {
    const { isPc } = useMedia();
    return (<div css={css `
        ${flex.v.allCenter}
        margin: auto;
        padding: ${isPc ? 0 : 8}px;
        width: ${isPc ? '60vw' : '100%'};
      `}>
      {aboutUsIntros.map((point, index) => (<div key={index} css={css `
            ${isPc ? flex.h.allCenter : flex.v.allCenter}
            margin-${isPc ? 'right' : 'bottom'}:48px;
            svg {
              font-size: 96px;
            }
          `}>
          <point.img css={css `
              color: ${globalGrey.g400};
            `}/>
          <div css={css `
              width: 72%;
              ${isPc && 'margin-left: 48px;'}
            `}>
            <p css={paragraphyTitleCss}>{point.title}</p>
            <p css={paragraphyContentCss}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{point.description}
            </p>
          </div>
        </div>))}
    </div>);
});
const aboutUsIntros = [
    {
        img: BsPeople,
        title: '團隊起源',
        description: `在各個行業都鼓吹跨領域整合，平台經濟，大數據分析的同時；一群原本應該在工程界、學界的小夥子，遺憾地發現台灣的金融業缺席了。當西蒙斯橫掃華爾街帶來新的衝擊，寬客崛起，台灣卻停留在十年前的階段緩步前進。我們發現了這件事，幾經討論，對交易充滿熱情的團隊就在新竹誕生了。`,
    },
    {
        img: BsLightning,
        title: '核心理念',
        description: `用跨界思維，打造投資未來。我們始終相信科學理論是能解釋經濟現象的，再複雜的邏輯也是建立在最簡單的原理與定義上。 事實上，金融離不開物理學，物理學有很大的分支是統計力學，而現在討論的大數據分析與AI機器學習，其演算法也都是基礎科學的延伸， 而如何以更深一層次將科學與金融鍵結，就是我們團隊的工作了。 我們將用不同於傳統技術分析的高格局結構，建立雲端化的智慧投資平台，並提供客製化的程式交易服務， 讓金融投資能跨足更多方向，掌握市場先機。`,
    },
    {
        img: BsGearWideConnected,
        title: '技術底蘊',
        description: `對於金融交易，本團隊以科學化的方式切入，建構大數據資料庫，用程式演算法量化各種交易現象。 新世代交易數據系統，不能只有傳統K棒指標。更多的細部資料如成交明細(Tick)、五檔掛單(Bid Ask)、連動性多商品(權值股、期貨、選擇權、匯率、國際指數)都是資深操盤手參考的依據。 然而要能掌握如此廣泛的資料，底層技術必須有革命性的提昇。 本團隊以多年數據分析與資訊工程實務經驗，結合嚴謹交易操盤邏輯，將抽象的盤感具體量化，建立具可解釋性之統計分析與AI模型。`,
    },
];
const mainTextCss = css `
  ${fontWeight600};
  font-size: 16px;
  margin: 4px 0;
`;
const manBoxCss = css `
  margin: 0 8px;
  &:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
`;
const stepperCss = css `
  background: transparent;
  color: transparent;
  margin-left: 15px;
  position: absolute;
  top: 40%;
  width: 98%;
  user-select: none;
`;
const Team = memo(function Team() {
    const { isPad, isPc, isPhone } = useMedia();
    const [selectedTeammate, setSelectedTeammate] = useState();
    const [activeStep, setActiveStep] = useState(0);
    const imgCss = css `
    max-width: ${isPc ? 160 : isPad ? 160 : 120}px;
    margin: 0 8px;
  `;
    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };
    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };
    return (<div css={css `
        ${flex.v.allCenter}
        ${ai.stretch}
        width: ${isPc ? '80vw' : '92%'};
        margin: auto;
      `}>
      <div css={css `
          ${isPc ? flex.h.allCenter : flex.v.allCenter};
          flex-direction: ${isPc ? 'row' : 'column-reverse'};
          position: relative;
          flex: 1;
        `}>
        <div css={teamCarouselGroupCss}>
          {PEOPLE[activeStep].map((man, i) => (<div css={manBoxCss} key={i}>
              <img alt={`${man.position}-${man.name}的圖像`} src={man.imageUrl} onClick={() => setSelectedTeammate(man)} css={imgCss}/>
              <div css={manPositionBoxCss}>{man.position}</div>
            </div>))}
        </div>

        <MobileStepper css={stepperCss} variant='text' steps={PEOPLE_GROUP_LENGTH} position='static' activeStep={activeStep} nextButton={<Button size='large' onClick={handleNext} disabled={activeStep === PEOPLE_GROUP_LENGTH - 1}>
              {<KeyboardArrowRight />}
            </Button>} backButton={<Button size='large' onClick={handleBack} disabled={activeStep === 0}>
              {<KeyboardArrowLeft />}
            </Button>}/>
        {/* details */}
        <Dialog maxWidth='lg' open={!!selectedTeammate} onClose={() => setSelectedTeammate(null)}>
          {selectedTeammate && (<div css={css `
                position: relative;
                padding: ${isPc ? '0 24px 0 120px' : '8px'};
                ${!isPc && 'display:flex;flex-direction: column-reverse;'};
              `}>
              <div css={css `
                  ${selectedTeammate ? flex.v.default : 'display:none'};
                  ${isPc && 'margin: 48px 16px;padding: 24px 24px 64px 160px'};
                  ${!isPc && 'padding: 16px'};
                  position: relative;
                  background: ${globalBlueGrey.bg200};
                  border-radius: 24px;
                  min-height: 280px;
                  * > p {
                    color: ${globalBlueGrey.bg700};
                  }
                  & > p {
                    font-size: 16px;
                    margin: 0;
                  }
                `}>
                <div css={css `
                    ${flex.h.allCenter};
                    ${isPc && 'position: absolute; top: -24px; '}
                  `}>
                  <div css={css `
                      ${flex.h.allCenter};
                      background: ${globalBlueGrey.bg600};
                      height: 40px;
                      padding: 0 16px;
                      color: white;
                      font-size: 1.5rem;
                      ${fontWeight600};
                    `}>
                    {selectedTeammate.name}
                  </div>

                  {isPc && (<div css={css `
                        width: 0;
                        height: 0;
                        border-top: 20px solid transparent;
                        border-bottom: 20px solid transparent;
                        border-left: 20px solid ${globalBlueGrey.bg600};
                      `}/>)}
                </div>
                <div css={css `
                    ${isPc ? flex.h.allCenter : flex.v.allCenter};
                    ${isPc || 'flex-direction: column-reverse'};
                    ${ai.flexStart}
                    padding: 16px 0;
                    & > * {
                      flex: 1;
                    }
                  `}>
                  <div css={css `
                      margin: 16px 32px 0 0;
                    `}>
                    <>
                      {selectedTeammate.main?.map((p, index) => (<div key={index} css={css `
                            ${flex.h.crossCenter};
                            color: ${globalBlueGrey.bg700};
                          `}>
                          <BsFillCaretRightFill css={css `
                              margin-right: 8px;
                            `}/>
                          <p css={mainTextCss}>{p}</p>
                        </div>))}
                    </>
                  </div>
                  <div css={css `
                      ${flex.v.allCenter}
                      margin: auto;
                      border: white 1px solid;
                      border-radius: 8px;
                      padding: 16px;
                      ${fontWeight600};

                      p {
                        margin: 0;
                      }
                      p:nth-of-type(1) {
                        font-size: 1.5rem;
                      }
                      p:nth-of-type(2) {
                        color: white;
                      }
                    `}>
                    <p>{selectedTeammate.title}</p>
                    <p>{selectedTeammate.goodAt}</p>
                  </div>
                </div>
                <div css={css `
                    ${flex.v.default};
                    & > p {
                      margin: 4px 0;
                      font-size: 1rem;
                      ${fontWeight600};
                    }
                  `}>
                  {selectedTeammate.description.map((des, subIndex) => (<p key={subIndex}>{des}</p>))}
                </div>
              </div>
              <div css={css `
                  ${isPc ? flex.v.default : flex.v.crossCenter}
                  ${isPc && 'position: absolute; bottom: 48px; left: 0'};
                  & > img {
                    ${isPc || 'width: 240px'};
                  }
                `}>
                <img alt={`${selectedTeammate.position}-${selectedTeammate.name}的圖像`} src={selectedTeammate.imageUrl} css={css `
                    ${isPc && ' margin-left: 20px;'}
                    width: ${isPhone ? '120px' : '50%'};
                  `}/>
                <div css={css `
                    ${isPc ? flex.h.allCenter : 'display: none'};
                    width: 52%;
                    padding: 8px 16px;
                    margin: -6px 0 0;
                    background: ${globalBlueGrey.bg500};
                    border-radius: 8px;
                    color: white;
                    ${fontWeight600};
                    font-size: 1.5rem;
                    margin-left: 16px;
                  `}>
                  {selectedTeammate.position}
                </div>
              </div>
            </div>)}
        </Dialog>
        {/* photo & title */}
      </div>
    </div>);
});
export default FuturesHomeNextPage;
const PEOPLE = [
    [
        {
            name: 'Miller',
            position: '後端工程師',
            main: [
                '清大資應所碩士畢業',
                '負責系統後端服務開發與維護',
                '熟悉多種程式開發流程',
                '擅長串接API資料及規劃架構',
            ],
            description: [
                '有串接與解析各式國內外報價的豐富經驗',
                '秒讀秒回的優質好男人 24小時on call解決疑難雜症',
                '是團隊最堅強的後盾，還是達康.com的鐵粉',
            ],
            title: '控場型後端API工程師',
            goodAt: '只要你講得出來，他就寫得出來',
            imageUrl: '/futuresai/index/figures/miller.png',
        },
        {
            name: 'Cory',
            position: '技術長',
            main: [
                '清大資工碩士畢業',
                '多年程式開發經驗',
                '擅長架構大數據分析工具',
                '專業客製化各大B2B平台',
            ],
            description: [
                '身為期天的CTO總工頭，手把手建立起了初代系統網站和眾多指標。',
                '然而即便擁有如此驚人才能，卻有著天選之人的反指標體質',
                '只要他出手、必定盤勢大亂，是個自稱宅男但興趣卻是登百岳的矛盾綜合體',
            ],
            title: '期天首席工程技術長',
            goodAt: '擅長商品：短線——台指、選擇權',
            imageUrl: '/futuresai/index/figures/cory.png',
        },
        {
            name: 'Osvaldo',
            position: '創辦人',
            main: [
                '交大機械所博士畢業',
                '十年國高中數理補教業經驗',
                '微股力專欄作家',
                '理財周刊專欄作家',
            ],
            description: [
                '擅長跨領域整合、數據分析、主觀交易，千杯不醉的技能至今無人挑戰成功，',
                '喜歡網羅各種奇才夥伴一起打拼，也喜歡無糖茶和行情',
            ],
            title: '量化主觀交易常勝軍',
            goodAt: '擅長商品：台指、選擇權、海期',
            // description2: `「希望常保教育熱誠，如果能幫到一些人就更好了。
            // 希望擁有許多朋友，如果能受人喜愛就太好了。
            // 希望滿足家人社會的期待，如果能超過就太幸運了。
            // 希望研究能幫助社會，如果有附加價值也不錯。
            // 最後，希望自己成為更好的人。」`,
            imageUrl: '/futuresai/index/figures/osvaldo.png',
        },
        {
            name: 'Walter',
            position: '策略長',
            main: [
                '交大資工系畢業',
                '清大研究室策略開發團隊',
                '目前已開發100多支策略',
                '擅長整理量化數據',
            ],
            description: [
                '交大資工背景的天才策略師，',
                '這位天才策略師有著絕對理性的頭腦，',
                '搭配量化分析專長，開發起程式策略交易的速度及績效直衝天際，',
                '是個戴眼鏡的狠角色',
            ],
            goodAt: '專長為程式策略交易，量化分析',
            title: '程式交易扛霸子，交大資工',
            imageUrl: '/futuresai/index/figures/walter.png',
        },
        {
            name: 'Ray',
            position: '研發長',
            main: ['清大經濟所博士畢業', '多年期權交易經驗', '擅長研究策略指標', '開發過多種交易小工具'],
            description: [
                '喜歡融匯各種金融知識來開發新交易指標',
                '常常能從一本本厚重的經濟論文中找到意想不到的點子',
                '是個溫文儒雅的讀書人、喜歡貓。',
            ],
            title: '指標研發首席博士，清大經濟博士',
            goodAt: '主攻總體經濟、國際情勢和權值股動向',
            imageUrl: '/futuresai/index/figures/ray.png',
        },
    ],
    [
        {
            name: 'Jia0',
            position: '前端工程師',
            main: [
                '政大經濟系畢業',
                '負責網站前端介面程式開發',
                '擅長維護及優化頁面效能',
                '具三年以上前端開發經驗',
            ],
            title: '速度型前端工程師',
            description: [
                '經濟念念跑去當工程師，不小心一做就是三年',
                '加入期天後常覺得「你各位賺錢到底為啥拿哞簡單」',
                '擅長寫很多漂亮的債',
                '喜歡神奇寶貝炸雞哲學還有一些老港片',
            ],
            goodAt: '早上溝通晚上交貨，沒有最快只有更快',
            imageUrl: '/futuresai/index/figures/jia0.png',
        },
        {
            name: 'Wei Jie',
            position: '前端工程師',
            main: [
                '擅長程式交易指標設計',
                '執行策略開發與架構建立',
                '負責編寫前端及看盤指標',
                '具6年以上交易經驗',
            ],
            title: '視覺強迫症的前端工程師',
            description: [
                '曾經誤入歧途但迷途知返的斜槓青年',
                '喜歡用個人美感建立一個友善且順暢的頁面',
                '熱愛燒肉且食量超大，五盤肉搭三碗飯都完全沒問題',
            ],
            goodAt: '擅長商品：台指、程式交易',
            imageUrl: '/futuresai/index/figures/weijei.png',
        },
        {
            name: 'TingMing',
            position: '後端工程師',
            main: [
                '交大資工系畢業',
                '協助研發軟體新技術與工具',
                '協助優化系統性能與除錯',
                '負責數據／報表的視覺化整理',
            ],
            title: '輔助型後端工程師',
            description: [
                '常稱自己為無情的小碼農，因緣際會成為期天的一份子',
                '主要負責籌碼統整、爬蟲和各類後端相關作業',
                '現在最大心願是兩年內能從清大資應研究所準時畢業',
            ],
            goodAt: '說一不二的認真好青年',
            imageUrl: '/futuresai/index/figures/tingming.png',
        },
        {
            name: 'Wen',
            position: '後端工程師',
            main: [
                '清大資工所畢業',
                '擅長Python, C, C#等多種語法',
                '負責後端各式串接整合及修訂',
                '具6年以上程式開發經驗',
            ],
            title: '學習中的後端工程師，清大資工所碩士',
            description: [
                '跟Miller同屆畢業後一起踏上這條不歸路，後端資歷淺短但滿懷熱忱',
                '只要一台筆電不管在哪都可以coding，海邊、酒吧、客運上',
                '最喜歡的城市—台南，最愛的鞋子—newbalance',
            ],
            goodAt: '沒有bug可以逃過他敏銳的雙眼',
            imageUrl: '/futuresai/index/figures/wenwei.png',
        },
        {
            name: 'Wendy',
            position: '行銷小編',
            main: [
                '負責影片拍攝、剪輯、美編',
                '行銷、管理粉專YT、文案企劃',
                '客服、行政及一切大小事',
                '除了寫程式外能做都做的女子',
            ],
            title: '誤入神團的愚民小編',
            description: [
                '擁有超乎常人的熱情和鬥志，志在將期天的產品和團隊推廣給全市場知道',
                '喜歡各國戲劇和音樂、有時會不小心暴露宅屬性',
                '養了一隻叫「學弟」的短腳暹羅貓',
            ],
            goodAt: '新手小韭菜：選擇權價差、台指',
            imageUrl: '/futuresai/index/figures/wendy.png',
        },
    ],
];
const PEOPLE_GROUP_LENGTH = 2;
